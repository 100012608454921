.sidebar-nav {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: fixed;
    right: 0;
    padding-right: 5%;
    bottom: 10%;
    background-color: transparent;
  }
  
  a {
    text-decoration: none !important;
    color: #08d665 !important;
  }
  
  a:hover {
    color: #ccd6f6 !important;
  }
  
  .sidebar-logos {
    padding-top: 42px;
    width: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 8px;
  }
  
  @media only screen and (max-width: 600px) {
    .sidebar-nav {
      width: 100%;
      height: 50px;
    }
  
    .sidebar-logos {
      padding-top: 0px;
      width: 100px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding-left: 50%;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .sidebar-nav {
      position: unset;
      align-items: center;
    }
    .sidebar-logos {
      width: 70px;
      padding-left: unset;
    }
  }
  