#footer {
    display: flex;
    align-items: center;
    padding-top: 100px;
}
  
.ending-footer {
    color: #8892b0;
    text-align: center;
    width: 100%;
    font-size: 16px;
    padding-bottom: 40px;
}