#intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 15%;
    padding-top: 10%;
    min-height: 100vh;
}

.intro-title {
    font-size: 86px !important;
}

.intro-name {
    color: #08d665;
    font-weight: bold;
    font-size: 86px !important;
}

.intro-subtitle {
    margin-top: -36px;
    color: #8892b0;
    font-weight: 300;
    font-size: 48px !important;
}
  
.intro-desc {
    color: #8892b0;
    font-size: 22px !important;
    max-width: 40%;
    text-align: justify;
    padding-bottom: 50px;
}

b {
    font-size: 17px;
}

@media only screen and (max-width: 600px) {
    #intro {
      min-height: unset;
      padding-left: unset;
      margin: 0;
      align-items: center;
    }
  
    .intro-title {
      font-size: 46px !important;
    }
    
    .intro-name {
      font-size: 46px !important;
    }
  
    .intro-subtitle {
      margin: 0;
      font-size: 28px !important;
      text-align: center;
    }
  
    .intro-desc {
      font-size: 18px !important;
      text-align: center;
      max-width: unset;
      padding-bottom: 40px;
    }
}
  