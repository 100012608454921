#projects {
    padding-left: 15%;
    padding-right: 25%;
    min-height: 60vh;
  }
  
  .projects-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(300px, 1fr));
    grid-gap: 10px;
  }
  @media (max-width: 1080px) {
    .projects-grid {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
  }
  
  .projects-card {
    position: relative;
    cursor: default;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    padding: 2rem 1.75rem;
    background-color: #112240;
    border-radius: 16px;
  }
  
  .projects-card:hover {
    transform: translateY(-7px);
    background-color: #233554;
  }
  
  .card-header {
    margin-top: -20px;
    display: flex;
    padding: 1.25em 0 !important;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: none !important;
  }
  .folder-icon {
    color: #08d665;
  }
  
  .github-icon {
    margin-top: 6px;
  }
  .github-icon:hover {
    color: #08d665;
  }
  
  .open-icon {
    margin-left: 10px;
  }
  
  .card-title {
    color: #ccd6f6;
    font-size: 24px !important;
    font-weight: bold;
  }
  
  .card-desc {
    color: #8892b0;
    font-size: 18px !important;
    padding-bottom: 40px;
  }
  
  .card-tech {
    color: #8892b0;
    font-size: 16px !important;
  }
  
  @media only screen and (max-width: 600px) {
    #projects {
      height: unset;
      padding-left: unset;
      padding-right: unset;
    }
  }
  